<template>
  <div>
    <div class="row mb-2">
      <div class="col my-auto">
        <p v-if="(billingSubscription.status === 'active'
                || billingSubscription.status === 'trialing')
                && billingSubscription.billing_product.hierarchy > 0
                && invoice"
           class="alert alert-primary mb-2 mt-2">
          <i class="far fa-info-circle me-1"></i>
          Next payment: <b>£{{(invoice.total / 100).toFixed(2)}}</b> on <b>{{ invoice.next_payment_attempt | formatDateUnix }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["billingSubscription", "invoice"],
  data() {
    return {
    }
  },
  components: {

  },
  methods: {
  },
  computed: {
  },
  filters: {
    formatDateUnix(date) {
      return moment.unix(date).format("LL");
    }
  },
  mounted() {
  }
};
</script>

<style>
</style>