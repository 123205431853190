<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <upcoming-payment-alert :billing-subscription="billingSubscription"
                                    :invoice="upcomingInvoice"
                                    :user="user">
            </upcoming-payment-alert>

            <my-subscription :billing-subscription="billingSubscription"
                             :user="user">
            </my-subscription>

            <my-plan :billing-subscription="billingSubscription"
                     :user="user">
            </my-plan>

            <h4 v-if="this.upcomingInvoice" class="mb-2">
              Upcoming Invoice <span class="small">({{upcomingInvoice.next_payment_attempt | formatDateUnix}})</span>
            </h4>
            <upcoming-invoice v-if="this.upcomingInvoice"
                              :invoice="upcomingInvoice"
                              :hide-date="true"
                              class="mx-3"
            ></upcoming-invoice>

            <usage :user="user"></usage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BillingNav from "../partials/BillingNav";
import Usage from "./subscription-partials/Usage";
import UpcomingInvoice from "./subscription-partials/UpcomingInvoice";
import MyPlan from "./subscription-partials/MyPlan";
import MySubscription from "./subscription-partials/MySubscription";
import UpcomingPaymentAlert from "./subscription-partials/UpcomingPaymentAlert";
export default {
  props: [],
  data() {
    return {
      upcomingInvoice: null,
    }
  },
  components: {
    UpcomingPaymentAlert,
    MySubscription,
    MyPlan,
    UpcomingInvoice,
    Usage,
    BillingNav
  },
  methods: {
    goTo(url){
      window.location = url;
    },
    fetchUpcomingInvoice() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/billing/fetch-upcoming-invoice").then(({ data }) => {
        this.upcomingInvoice = data;
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
    billingSubscription() {
      return this.user.clinic.billing_subscription;
    },
  },
  filters: {
    formatDateUnix(date) {
      return moment.unix(date).format("LL");
    }
  },
  mounted() {
    this.fetchUpcomingInvoice();
  }
};
</script>

<style>
</style>
