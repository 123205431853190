<template>
  <div>
    <h4 class="mb-2">
      My Subscription
    </h4>
    <div class="row mb-3 py-3 bg-light rounded">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="media d-flex">
              <div class="align-self-center me-4">
                <i class="far fa-flask-potion fa-3x text-primary float-start mb-1"></i>
              </div>
              <div class="media-body">
                <h3 class="mb-3">
                  {{ billingSubscription.billing_product.title }}
                </h3>
                <span class="h5 mb-0 fw-bold text-uppercase text-primary">My Plan</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="media d-flex">
              <div class="align-self-center me-4">
                <i class="far fa-calendar fa-3x text-primary float-start mb-1"></i>
              </div>
              <div class="media-body">
                <h3 class="mb-3 text-capitalize" v-if="!billingSubscription.cancel_at_period_end">{{ billingSubscription.current_period_end | formatDate }}</h3>
                <h3 class="mb-3 text-capitalize text-danger" v-else>CANCELED</h3>
                <span class="h5 mb-0 fw-bold text-uppercase text-primary">Renewal Date</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["billingSubscription"],
  data() {
    return {
    }
  },
  components: {

  },
  methods: {
  },
  computed: {
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  mounted() {
    this.$EventBus.$emit("refreshUser");
  }
};
</script>

<style>
</style>
