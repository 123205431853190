<template>
  <div>
    <h4 class="mb-2">
      My Plan
    </h4>
    <div class="row mb-3 py-3 bg-light rounded">
      <div class="col-4 my-2" v-for="u in users">
        <div class="card">
          <div class="card-img-top bg-primary p-2 text-center text-white">
            <i class="far fa-3x mt-2" :class="u.billing_subscription_item.billing_plan.user_role | formatRoleAsIcon"></i>
            <p class="text-capitalize mb-0 fw-bold">{{ u.name }}</p>
            <p class="text-capitalize my-1">{{ u.billing_subscription_item.billing_plan.user_role }}</p>
          </div>
          <div class="card-body">
            <div class="card-text text-center">
              <p class="text-capitalize">{{ u.billing_subscription_item.billing_plan.billing_product.title }}</p>
              <p class="fw-bold m-0">
                £{{u.billing_subscription_item.billing_plan.price.toFixed(2)}}
              </p>
              <p class="m-0">
                <i>({{ u.billing_subscription_item.billing_plan.billing_period }})</i>
              </p>
              <p class="badge bg-primary mb-0">Active</p>
              <p class="font-italic small">Renews: {{ billingSubscription.current_period_end | formatDate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["billingSubscription", "user"],
  data() {
    return {
      users: [],
    }
  },
  components: {

  },
  methods: {
    fetchClinicUsers() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/clinic/fetch-clinic-users").then(({ data }) => {
        this.users = data;
      });
    }
  },
  computed: {
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatRoleAsIcon(role) {
      switch (role){
        case "practitioner":
          return "fa-user-md";
        case "owner":
          return "fa-user-crown";
        case "administrator":
          return "fa-user-cog"
      }
    },
  },
  mounted() {
    this.fetchClinicUsers();
  }
};
</script>

<style>
</style>
