<template>
  <div>
    <h4 class="mb-2">Usage</h4>
    <div class="row mb-4 bg-light rounded px-2 py-3 mx-1">
              <div class="col-6">
                <div class="card shadow-sm border-0">
                  <div class="card-body text-center">
                    <h4 class="mb-3">Storage</h4>

                    <div class="mb-3">
                      <!-- Progress bar 1 -->
                      <div class="progress mx-auto" :data-value="storageLimit > 0 ? 0 : 100">
                        <span class="progress-left">
                          <span class="progress-bar border-primary"></span>
                        </span>
                        <span class="progress-right">
                          <span class="progress-bar border-primary"></span>
                        </span>
                        <div v-if="storageLimit > 0" class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div class="h2 fw-bold">0<sup class="small">%</sup></div>
                        </div>
                        <div v-else class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div class="h5 fw-bold"><small>UNLIMITED</small></div>
                        </div>
                      </div>
                      <!-- END -->
                    </div>

                    <h5 v-if="storageLimit > 0">0 GB / {{storageLimit + " GB"}}</h5>
                    <h5 v-else>Unlimited</h5>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card shadow-sm border-0">
                  <div class="card-body text-center">
                    <h4 class="mb-3">Clients</h4>

                    <div class="mb-3">
                      <!-- Progress bar 1 -->
                      <div class="progress mx-auto" :data-value="clientLimit > 0 ? (user.clinic.client_count / clientLimit) * 100 : 100">
                        <span class="progress-left">
                          <span class="progress-bar border-primary"></span>
                        </span>
                        <span class="progress-right">
                          <span class="progress-bar border-primary"></span>
                        </span>
                        <div v-if="clientLimit > 0" class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div class="h2 fw-bold">{{ ((user.clinic.client_count / clientLimit) * 100).toFixed(0) }}<sup class="small">%</sup></div>
                        </div>
                        <div v-else class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div class="h5 fw-bold"><small>UNLIMITED</small></div>
                        </div>
                      </div>
                      <!-- END -->
                    </div>

                    <h5 v-if="clientLimit > 0">{{user.clinic.client_count}} / {{clientLimit}}</h5>
                    <h5 v-else>Unlimited</h5>
                  </div>
                </div>
              </div>
            </div>
  </div>
</template>

<script>

export default {
  props: ["user"],
  data() {
    return {
    }
  },
  components: {

  },
  methods: {
    percentageToDegrees(percentage) {
      return percentage / 100 * 360;
    },
  },
  computed: {
    storageLimit() {
      return this.user.clinic.billing_subscription.billing_product.storage_limit_gb;
    },
    clientLimit() {
      return this.user.clinic.billing_subscription.billing_product.client_limit;
    }
  },
  filters: {
  },
  mounted() {
    var vm = this;
    $(".progress").each(function() {
      var value = $(this).attr('data-value');
      var left = $(this).find('.progress-left .progress-bar');
      var right = $(this).find('.progress-right .progress-bar');

      if (value > 0) {
        if (value <= 50) {
          right.css('transform', 'rotate(' + vm.percentageToDegrees(value) + 'deg)')
        } else {
          right.css('transform', 'rotate(180deg)')
          left.css('transform', 'rotate(' + vm.percentageToDegrees(value - 50) + 'deg)')
        }
      }

    })
  }
};
</script>

<style>
  .progress {
    width: 150px;
    height: 150px;
    background: none;
    position: relative;
  }

  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress .progress-left {
    left: 0;
  }

  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 10px;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }

  .progress .progress-right {
    right: 0;
  }

  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }

  .progress .progress-value {
    position: absolute;
    top: 0;
    left: 0;
  }

</style>